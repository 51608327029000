
import { markdown } from "@/filters/string";
import { ApiResponse, axios } from "@/libraries/axios";
import { session, settings } from "@/libraries/storage";
import { TokenRecord } from "@/views/Nft/NftInterface";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from "vue-router/types/router";
import { namespace } from "vuex-class";

const CoreStore = namespace('CoreStore')
const VrStore = namespace('VrStore')

@Component({
    methods: {markdown}
})
export default class NftView extends Vue {
    @Prop({
        type: String
    }) readonly smartContractAddress!: string

    @Prop({
        type: String
    }) readonly tokenId!: string

    @CoreStore.State
    hasShareCapability!: boolean

    @VrStore.Mutation
    setDarkMode!: (darkMode: boolean) => void

    @VrStore.State
    darkMode!: boolean
    @VrStore.State
    hasDarkMode!: boolean
    @VrStore.State
    hasLightMode!: boolean

    aspectRatio = 1

    videoContainer: HTMLVideoElement | null = null
    token: TokenRecord | false | null = null

    beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
        if (!session.has('system','ux', 'bootstrap') ||
            (Date.now() - session.get<number>('system','ux', 'bootstrap') > 30 * 60 * 1_000)
        ) {
            next(vm => {
                const route = vm.$router.match('/')

                if (route && route.meta) {
                    route.meta.redirectTo = to.path
                }

                next('/')
            })
        } else {
            next()
        }
    }

    async created(): Promise<void> {
        await this.load()

        if (this.token) {
            if (this.videoContainer) {
                this.videoContainer.addEventListener('loadedmetadata', (event) => {
                    const video = event.target as HTMLVideoElement

                    this.aspectRatio = video.videoWidth / video.videoHeight
                })
            } else {
                const img = new Image()
                img.addEventListener('load', () => {
                    this.aspectRatio = img.width / img.height
                })
                img.src = this.token.image
            }
        }
    }

    get appName(): string {
        return settings.get<string>('ux', 'theme', 'longName')
    }

    load(): Promise<void> {
        return axios.get<ApiResponse<TokenRecord>>(`/v1/nft/${this.smartContractAddress}/${this.tokenId}`)
            .then(({data, status}) => {
                if (status === 200 && data.code === 302) {
                    this.token = data.pyld
                } else {
                    this.token = false
                }
            })
            .catch(() => {
                this.token = false
            })
            .finally(() => {
                //
            })
    }

    async share(): Promise<void> {
        if (this.token) {
            await navigator.share({
                text: this.token.description,
                title: this.token.name,
                url: this.token.animationUrl,
            })
        }
    }
}
